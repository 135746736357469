define("ember-bootstrap/components/bs-navbar/link-to", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsLinkTo
    @route={{@route}}
    @model={{@model}}
    @models={{@models}}
    @query={{@query}}
    @disabled={{@disabled}}
    @class={{@class}}
    {{on 'click' this.onClick}}
    ...attributes
  >
    {{yield}}
  </BsLinkTo>
  */
  {
    "id": "d1oDXFNu",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@route\",\"@model\",\"@models\",\"@query\",\"@disabled\",\"@class\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"default\"],[[[[1,\"\\n  \"],[18,8,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@route\",\"@model\",\"@models\",\"@query\",\"@disabled\",\"@class\",\"&default\"],false,[\"bs-link-to\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar/link-to.hbs",
    "isStrictMode": false
  });
  /**
   * Extended `{{link-to}}` component for use within Navbars.
   *
   * @class NavbarLinkTo
   * @namespace Components
   * @extends Components.NavLinkTo
   * @public
   */
  let NavbarLinkTo = _exports.default = (_class = class NavbarLinkTo extends _component2.default {
    /**
     * @property collapseNavbar
     * @type {Boolean}
     * @default true
     * @public
     */

    onClick() {
      if (this.args.collapseNavbar ?? true) {
        this.args.onCollapse();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavbarLinkTo);
});
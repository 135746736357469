define("ember-bootstrap/components/bs-dropdown/toggle", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "@ember/object", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _defaultDecorator, _object, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href="#"
    class="dropdown-toggle {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if @inNav "nav-link")}}"
    aria-expanded={{this.aria-expanded}}
    role="button"
    ...attributes
    {{on "keydown" this.handleKeyDown}}
    {{on "click" this.handleClick}}
    {{did-insert @registerChildElement "toggle"}}
    {{will-destroy @unregisterChildElement "toggle"}}
  >
    {{yield}}
  </a>
  */
  {
    "id": "giPjOKOy",
    "block": "[[[11,3],[24,6,\"#\"],[16,0,[29,[\"dropdown-toggle \",[52,[30,1],\"nav-link\"]]]],[16,\"aria-expanded\",[30,0,[\"aria-expanded\"]]],[24,\"role\",\"button\"],[17,2],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,2],[[30,3],\"toggle\"],null],[4,[38,3],[[30,4],\"toggle\"],null],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@inNav\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-dropdown/toggle.hbs",
    "isStrictMode": false
  });
  /**
   Anchor element that triggers the parent dropdown to open.
   Use [Components.DropdownButton](Components.DropdownButton.html) if you want a button instead of an anchor tag.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownToggle
   @namespace Components
   @extends Ember.Component
   @publicø
   */
  let DropdownToggle = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('isOpen'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class DropdownToggle extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * @property inNav
       * @type {boolean}
       * @private
       */
      _initializerDefineProperty(this, "inNav", _descriptor, this);
    }
    get 'aria-expanded'() {
      return this.isOpen ? 'true' : 'false';
    }

    /**
     * When clicking the toggle this action is called.
     *
     * @event onClick
     * @param {*} value
     * @public
     */
    onClick() {}
    handleClick(e) {
      e.preventDefault();
      this.onClick();
    }
    handleKeyDown(e) {
      this.onKeyDown(e);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "inNav", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, 'aria-expanded', [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, 'aria-expanded'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownToggle);
});
define("ember-bootstrap/components/bs-accordion/item/title", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-nested-interactive --}}
  {{!-- @todo fix this, see https://github.com/kaliber5/ember-bootstrap/issues/999 --}}
  <div
    class="{{if @collapsed "collapsed" "expanded"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card-header"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-heading"}}"
    role="tab"
    ...attributes
    {{on "click" this.handleClick}}
  >
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
      <h5 class="mb-0">
        <button class="btn btn-link {{if @disabled "disabled"}}" type="button" disabled={{@disabled}}>
          {{yield}}
        </button>
      </h5>
    {{/if}}
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
      <h4 class="panel-title">
        <a href="#" class={{if @disabled "disabled"}}>
          {{yield}}
        </a>
      </h4>
    {{/if}}
  </div>
  */
  {
    "id": "eIEYDWJ/",
    "block": "[[[11,0],[16,0,[29,[[52,[30,1],\"collapsed\",\"expanded\"],\" \",\"card-header\",\" \",[27]]]],[24,\"role\",\"tab\"],[17,2],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[1,\"\\n      \"],[10,\"button\"],[15,0,[29,[\"btn btn-link \",[52,[30,3],\"disabled\"]]]],[15,\"disabled\",[30,3]],[14,4,\"button\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@collapsed\",\"&attrs\",\"@disabled\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item/title.hbs",
    "isStrictMode": false
  });
  /**
   Component for an accordion item title.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemTitle
   @namespace Components
   @extends Glimmer.Component
   @public
   */
  let AccordionItemTitle = _exports.default = (0, _deprecateSubclassing.default)(_class = (_class2 = class AccordionItemTitle extends _component2.default {
    /**
     * @property collapsed
     * @type boolean
     * @public
     */

    /**
     * @property disabled
     * @type boolean
     * @private
     */

    /**
     * @event onClick
     * @public
     */

    handleClick(e) {
      e.preventDefault();
      if (!this.args.disabled) {
        this.args.onClick?.();
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _class2)) || _class;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionItemTitle);
});
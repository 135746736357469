define("ember-cli-analytics/utils/without", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = without;
  /*
   * Return an object with each of the
   * listed keys removed.
   *
   * @method without
   *
   * @param {Object} original
   *   The original object.
   *
   * @param {Rest} args
   *   A list of keys to remove from the original object.
   */
  function without(original, ...args) {
    const originalKeys = Object.keys(original);
    const newObject = {};
    originalKeys.forEach(function (key) {
      if (args.indexOf(key) === -1) {
        newObject[key] = original[key];
      }
    });
    return newObject;
  }
});
define("ember-bootstrap/components/bs-form/element/errors", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    {{#if this.showMultipleErrors}}
      <div class="pre-scrollable">
        {{#each @messages as |message|}}
          <div class={{if (macroCondition (macroGetOwnConfig "isBS3")) "help-block" "invalid-feedback d-block"}}>{{message}}</div>
        {{/each}}
      </div>
    {{else}}
      <div class={{if (macroCondition (macroGetOwnConfig "isBS3")) "help-block" "invalid-feedback d-block"}}>{{@messages.firstObject}}</div>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "3SfLK2Nk",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[30,0,[\"showMultipleErrors\"]],[[[1,\"    \"],[10,0],[14,0,\"pre-scrollable\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,1,[\"firstObject\"]]],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"@messages\",\"message\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/errors.hbs",
    "isStrictMode": false
  });
  /**
   @class FormElementErrors
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementErrors = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementErrors extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * @property show
       * @type {Boolean}
       * @public
       */
      _initializerDefineProperty(this, "show", _descriptor, this);
      /**
       * @property messages
       * @type {Ember.Array}
       * @public
       */
      /**
       * Whether or not should display several errors at the same time.
       *
       * @default false
       * @property showMultipleErrors
       * @public
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "showMultipleErrors", _descriptor2, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "show", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showMultipleErrors", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementErrors);
});
define("ember-bootstrap/components/bs-tab/pane", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/object/observers", "@ember/runloop", "ember-bootstrap/mixins/component-child", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator", "@ember/object/internals", "ember-bootstrap/utils/deprecate-subclassing", "ember-ref-bucket"], function (_exports, _component, _templateFactory, _component2, _object, _observers, _runloop, _componentChild, _transitionEnd, _usesTransition, _defaultDecorator, _internals, _deprecateSubclassing, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="tab-pane {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.showContent "show")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.showContent "in")}} {{if this.active "active"}} {{if this.usesTransition "fade"}}"
    role="tabpanel"
    ...attributes
    {{create-ref "mainNode"}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "KVfjq/Yw",
    "block": "[[[11,0],[16,0,[29,[\"tab-pane \",[52,[30,0,[\"showContent\"]],\"show\"],\" \",[27],\" \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"usesTransition\"]],\"fade\"]]]],[24,\"role\",\"tabpanel\"],[17,1],[4,[38,1],[\"mainNode\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"create-ref\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-tab/pane.hbs",
    "isStrictMode": false
  });
  /**
   The tab pane of a tab component.
   See [Components.Tab](Components.Tab.html) for examples.
  
   @class TabPane
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentChild
   @public
   */
  let TabPane = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _emberRefBucket.ref)('mainNode'), _dec3 = (0, _object.computed)('activeId', 'id').readOnly(), _dec4 = (0, _usesTransition.default)('fade'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class TabPane extends _component.default.extend(_componentChild.default) {
    constructor(...args) {
      super(...args);
      /**
       * @property id
       * @type null | HTMLElement
       */
      _initializerDefineProperty(this, "_element", _descriptor, this);
      /**
       * @property id
       * @type string
       * @public
       */
      _defineProperty(this, "id", (0, _internals.guidFor)(this));
      /**
       * @property activeId
       * @private
       */
      _initializerDefineProperty(this, "activeId", _descriptor2, this);
      /**
       * Used to apply Bootstrap's "active" class
       *
       * @property active
       * @type boolean
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "active", _descriptor3, this);
      /**
       * Used to trigger the Bootstrap visibility classes.
       *
       * @property showContent
       * @type boolean
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "showContent", _descriptor4, this);
      /**
       * The title for this tab pane. This is used by the `bs-tab` component to automatically generate
       * the tab navigation.
       * See the [Components.Tab](Components.Tab.html) for examples.
       *
       * @property title
       * @type string
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "title", _descriptor5, this);
      /**
       * An optional group title used by the `bs-tab` component to group all panes with the same group title
       * under a common drop down in the tab navigation.
       * See the [Components.Tab](Components.Tab.html) for examples.
       *
       * @property groupTitle
       * @type string
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "groupTitle", _descriptor6, this);
      /**
       * Use fade animation when switching tabs.
       *
       * @property fade
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "fade", _descriptor7, this);
      /**
       * The duration of the fade out animation
       *
       * @property fadeDuration
       * @type integer
       * @default 150
       * @private
       */
      _initializerDefineProperty(this, "fadeDuration", _descriptor8, this);
      /**
       * Use CSS transitions?
       *
       * @property usesTransition
       * @type boolean
       * @readonly
       * @private
       */
      _initializerDefineProperty(this, "usesTransition", _descriptor9, this);
    }
    /**
     * True if this pane is active (visible)
     *
     * @property isActive
     * @type boolean
     * @readonly
     * @private
     */
    get isActive() {
      return this.activeId === this.id;
    }
    /**
     * Show the pane
     *
     * @method show
     * @protected
     */
    show() {
      if (this.usesTransition) {
        if (!this._element) {
          // _element is initially set by `{{create-ref}}` which happens in next run loop, so can be undefined here.
          this.setProperties({
            active: true,
            showContent: true
          });
        } else {
          (0, _transitionEnd.default)(this._element, this.fadeDuration).then(() => {
            if (!this.isDestroyed) {
              this.setProperties({
                active: true,
                showContent: true
              });
            }
          });
        }
      } else {
        this.set('active', true);
      }
    }

    /**
     * Hide the pane
     *
     * @method hide
     * @protected
     */
    hide() {
      if (this.usesTransition) {
        (0, _transitionEnd.default)(this._element, this.fadeDuration).then(() => {
          if (!this.isDestroyed) {
            this.set('active', false);
          }
        });
        this.set('showContent', false);
      } else {
        this.set('active', false);
      }
    }
    _showHide() {
      if (this.isActive) {
        this.show();
      } else {
        this.hide();
      }
    }
    _setActive() {
      this.set('active', this.isActive);
      this.set('showContent', this.isActive && this.fade);
    }
    init() {
      super.init(...arguments);

      // isActive comes from parent component, so only available after render...
      (0, _runloop.scheduleOnce)('afterRender', this, this._setActive);
      (0, _observers.addObserver)(this, 'isActive', null, this._showHide, true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "activeId", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isActive", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "showContent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "groupTitle", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "usesTransition", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TabPane);
});
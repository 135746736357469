define("ember-api-actions/utils/serialize-and-push", ["exports", "@ember/array", "ember-api-actions/utils/build-url"], function (_exports, _array, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeAndPush;
  function serializeAndPush(response) {
    const recordClass = (0, _buildUrl._getModelClass)(this);
    const modelName = (0, _buildUrl._getModelName)(recordClass);
    const store = (0, _buildUrl._getStoreFromRecord)(this);
    const serializer = store.serializerFor(modelName);
    let normalized;
    if ((0, _array.isArray)(response)) {
      const doc = response;
      normalized = serializer.normalizeResponse(store, recordClass, doc, null, 'findAll');
    } else {
      const doc = response;
      normalized = serializer.normalizeResponse(store, recordClass, doc, `${doc.id || '(unknown)'}`, 'findRecord');
    }
    return store.push(normalized);
  }
});
define("ember-bootstrap/components/bs-progress/bar", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/deprecate-subclassing", "@embroider/macros/runtime"], function (_exports, _component, _templateFactory, _component2, _object, _defaultDecorator, _typeClass, _deprecateSubclassing, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    role="progressbar"
    aria-valuenow={{this.value}}
    aria-valuemin={{this.minValue}}
    aria-valuemax={{this.maxValue}}
    class="progress-bar {{if this.striped "progress-bar-striped"}} {{this.typeClass}} {{if this.animate (if (macroCondition (macroGetOwnConfig "isNotBS3")) "progress-bar-animated" "active")}}"
    ...attributes
    {{style width=this.percentStyleValue}}
  >
    {{#if this.showLabel}}
      {{#if (has-block)}}
        {{yield this.percentRounded}}
      {{else}}
        {{this.percentRounded}}%
      {{/if}}
    {{else}}
      {{#if (has-block)}}
        <span class={{if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only"}}>{{yield this.percentRounded}}</span>
      {{else}}
        <span class={{if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only"}}>{{this.percentRounded}}%</span>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "FdTdIhnS",
    "block": "[[[11,0],[24,\"role\",\"progressbar\"],[16,\"aria-valuenow\",[30,0,[\"value\"]]],[16,\"aria-valuemin\",[30,0,[\"minValue\"]]],[16,\"aria-valuemax\",[30,0,[\"maxValue\"]]],[16,0,[29,[\"progress-bar \",[52,[30,0,[\"striped\"]],\"progress-bar-striped\"],\" \",[30,0,[\"typeClass\"]],\" \",[52,[30,0,[\"animate\"]],\"progress-bar-animated\"]]]],[17,1],[4,[38,1],null,[[\"width\"],[[30,0,[\"percentStyleValue\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showLabel\"]],[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,0,[\"percentRounded\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"percentRounded\"]]],[1,\"%\\n\"]],[]]]],[]],[[[41,[48,[30,2]],[[[1,\"      \"],[10,1],[14,0,\"sr-only\"],[12],[18,2,[[30,0,[\"percentRounded\"]]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"sr-only\"],[12],[1,[30,0,[\"percentRounded\"]]],[1,\"%\"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"style\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-progress/bar.hbs",
    "isStrictMode": false
  });
  /**
  
   Component for a single progress bar, see [Components.Progress](Components.Progress.html) for more examples.
  
   @class ProgressBar
   @namespace Components
   @extends Ember.Component
   @public
   */
  let ProgressBar = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _typeClass.default)((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isNotBS3) ? 'bg' : 'progress-bar', 'type'), _dec3 = (0, _object.computed)('value', 'minValue', 'maxValue').readOnly(), _dec4 = (0, _object.computed)('percent', 'roundDigits').readOnly(), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class ProgressBar extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * The lower limit of the value range
       *
       * @property minValue
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "minValue", _descriptor, this);
      /**
       * The upper limit of the value range
       *
       * @property maxValue
       * @type number
       * @default 100
       * @public
       */
      _initializerDefineProperty(this, "maxValue", _descriptor2, this);
      /**
       * The value the progress bar should represent
       *
       * @property value
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "value", _descriptor3, this);
      /**
       If true a label will be shown inside the progress bar.
        By default it will be the percentage corresponding to the `value` property, rounded to `roundDigits` digits.
       You can customize it by using the component with a block template, which the component yields the percentage
       value to:
        ```hbs
       {{#bs-progress}}
       {{#bs-progress-bar value=progressValue as |percent|}}{{progressValue}} ({{percent}}%){{/bs-progress-bar}}
       {{/bs-progress}}
       ```
        @property showLabel
       @type boolean
       @default false
       @public
       */
      _initializerDefineProperty(this, "showLabel", _descriptor4, this);
      /**
       * Create a striped effect, see http://getbootstrap.com/components/#progress-striped
       *
       * @property striped
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "striped", _descriptor5, this);
      /**
       * Animate the stripes, see http://getbootstrap.com/components/#progress-animated
       *
       * @property animate
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "animate", _descriptor6, this);
      /**
       * Specify to how many digits the progress bar label should be rounded.
       *
       * @property roundDigits
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "roundDigits", _descriptor7, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/progress/#backgrounds)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      _initializerDefineProperty(this, "type", _descriptor8, this);
      _initializerDefineProperty(this, "typeClass", _descriptor9, this);
    }
    /**
     * The percentage of `value`
     *
     * @property percent
     * @type number
     * @protected
     * @readonly
     */
    get percent() {
      let value = parseFloat(this.value);
      let minValue = parseFloat(this.minValue);
      let maxValue = parseFloat(this.maxValue);
      return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1) * 100;
    }

    /**
     * The percentage of `value`, rounded to `roundDigits` digits
     *
     * @property percentRounded
     * @type number
     * @protected
     * @readonly
     */
    get percentRounded() {
      let roundFactor = Math.pow(10, this.roundDigits);
      return Math.round(this.percent * roundFactor) / roundFactor;
    }
    get percentStyleValue() {
      let percent = this.percent;
      return !isNaN(percent) ? `${percent}%` : '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "minValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "maxValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "value", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "showLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "striped", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "animate", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "roundDigits", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "percent", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "percentRounded", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "percentRounded"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProgressBar);
});
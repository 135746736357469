define("ember-macro-helpers/get-value", ["exports", "@ember/object", "@ember/utils", "ember-macro-helpers/-computed-store"], function (_exports, _object, _utils, _computedStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default({
    context,
    macro,
    key
  } = {}) {
    let cp = _computedStore.default.get(macro);
    if (cp) {
      return cp.getter.call(context, key);
    }
    if (typeof macro !== 'string') {
      return macro;
    }
    if ((0, _utils.isBlank)(macro)) {
      // the macro was `[]' or `@each.key', which has been trimmed, leaving a
      // blank string, so return the context (which is likely an ArrayProxy)
      return context;
    }
    return (0, _object.get)(context, macro);
  }
});
define("ember-bootstrap/config", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Config extends _object.default {}
  Config.reopenClass({
    formValidationSuccessIcon: 'glyphicon glyphicon-ok',
    formValidationErrorIcon: 'glyphicon glyphicon-remove',
    formValidationWarningIcon: 'glyphicon glyphicon-warning-sign',
    formValidationInfoIcon: 'glyphicon glyphicon-info-sign',
    insertEmberWormholeElementToDom: true,
    load(config = {}) {
      for (let property in config) {
        if (Object.prototype.hasOwnProperty.call(this, property) && typeof this[property] !== 'function') {
          this[property] = config[property];
        }
      }
    }
  });
  var _default = _exports.default = Config;
});
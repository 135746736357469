define("ember-bootstrap/components/bs-form/element/control/radio", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _control, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-positive-tabindex --}}
  {{#each @options as |option index|}}
    {{#let (concat @id "-" index) as |id|}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        {{#if this.inline}}
          <label
            for={{id}}
            class="radio-inline"
          >
            <input
              type="radio"
              id={{id}}
              checked={{bs-eq option @value}}
              onclick={{fn this.onChange option}}
              name={{@name}}
              required={{@required}}
              disabled={{@disabled}}
              autofocus={{@autofocus}}
              tabindex={{@tabindex}}
              form={{@form}}
              title={{@title}}
              ...attributes
            >
            {{#if (has-block)}}
              {{yield option index}}
            {{else}}
              {{#if @optionLabelPath}}
                {{get option @optionLabelPath}}
              {{else}}
                {{option}}
              {{/if}}
            {{/if}}
          </label>
        {{else}}
          <div class="radio">
  
            <label
              for={{id}}
            >
              <input
                type="radio"
                id={{id}}
                checked={{bs-eq option @value}}
                onclick={{fn this.onChange option}}
                name={{@name}}
                required={{@required}}
                disabled={{@disabled}}
                autofocus={{@autofocus}}
                tabindex={{@tabindex}}
                form={{@form}}
                title={{@title}}
                ...attributes
              >
              {{#if (has-block)}}
                {{yield option index}}
              {{else}}
                {{#if @optionLabelPath}}
                  {{get option @optionLabelPath}}
                {{else}}
                  {{option}}
                {{/if}}
              {{/if}}
            </label>
          </div>
        {{/if}}
      {{else}}
        <div class="form-check{{if this.inline " form-check-inline"}}">
          <input
            type="radio"
            class="form-check-input"
            id={{id}}
            checked={{bs-eq option @value}}
            onclick={{fn this.onChange option}}
            name={{@name}}
            required={{@required}}
            disabled={{@disabled}}
            autofocus={{@autofocus}}
            tabindex={{@tabindex}}
            form={{@form}}
            title={{@title}}
            ...attributes
          >
          <label
            for={{id}}
            class="form-check-label"
          >
            {{#if (has-block)}}
              {{yield option index}}
            {{else}}
              {{#if @optionLabelPath}}
                {{get option @optionLabelPath}}
              {{else}}
                {{option}}
              {{/if}}
            {{/if}}
          </label>
        </div>
      {{/if}}
    {{/let}}
  {{/each}}
  
  */
  {
    "id": "EqfYHR5m",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[44,[[28,[37,3],[[30,4],\"-\",[30,3]],null]],[[[1,\"      \"],[10,0],[15,0,[29,[\"form-check\",[52,[30,0,[\"inline\"]],\" form-check-inline\"]]]],[12],[1,\"\\n        \"],[11,\"input\"],[24,4,\"radio\"],[24,0,\"form-check-input\"],[16,1,[30,5]],[16,\"checked\",[28,[37,5],[[30,2],[30,6]],null]],[16,\"onclick\",[28,[37,6],[[30,0,[\"onChange\"]],[30,2]],null]],[16,3,[30,7]],[16,\"required\",[30,8]],[16,\"disabled\",[30,9]],[16,\"autofocus\",[30,10]],[16,\"tabindex\",[30,11]],[16,\"form\",[30,12]],[16,\"title\",[30,13]],[17,14],[12],[13],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,5]],[14,0,\"form-check-label\"],[12],[1,\"\\n\"],[41,[48,[30,16]],[[[1,\"            \"],[18,16,[[30,2],[30,3]]],[1,\"\\n\"]],[]],[[[41,[30,15],[[[1,\"              \"],[1,[28,[35,9],[[30,2],[30,15]],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,2]],[1,\"\\n\"]],[]]]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[5]]]],[2,3]],null]],[\"@options\",\"option\",\"index\",\"@id\",\"id\",\"@value\",\"@name\",\"@required\",\"@disabled\",\"@autofocus\",\"@tabindex\",\"@form\",\"@title\",\"&attrs\",\"@optionLabelPath\",\"&default\"],false,[\"each\",\"-track-array\",\"let\",\"concat\",\"if\",\"bs-eq\",\"fn\",\"has-block\",\"yield\",\"get\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/control/radio.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementControlRadio
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlRadio = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementControlRadio extends _control.default {
    constructor(...args) {
      super(...args);
      /**
       * @property inline
       * @type {Boolean}
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "inline", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "inline", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementControlRadio);
});
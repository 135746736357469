define("ember-bootstrap/helpers/bs-size-class", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sizeClassHelper = sizeClassHelper;
  function sizeClassHelper([prefix, size], {
    default: defaultValue
  }) {
    size = size ?? defaultValue;
    return (0, _utils.isBlank)(size) ? null : `${prefix}-${size}`;
  }
  var _default = _exports.default = (0, _helper.helper)(sizeClassHelper);
});
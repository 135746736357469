define("ember-macro-helpers/raw", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(key) {
    return (0, _object.computed)(() => key).readOnly();
  }
});
define("ember-bootstrap/components/bs-navbar", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember-decorators/object", "@ember/object", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/default-decorator", "@ember/debug", "@ember/utils", "ember-bootstrap/utils/deprecate-subclassing", "@embroider/macros/runtime"], function (_exports, _component, _templateFactory, _component2, _object, _object2, _listenTo, _defaultDecorator, _debug, _utils, _deprecateSubclassing, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-duplicate-landmark-elements --}}
  {{#let
    (hash
      toggle=(component (ensure-safe-component (bs-default @toggleComponent (component "bs-navbar/toggle"))) onClick=this.toggleNavbar collapsed=this._collapsed)
      content=(component (ensure-safe-component (bs-default @contentComponent (component "bs-navbar/content"))) collapsed=this._collapsed onHidden=this.onCollapsed onShown=this.onExpanded)
      nav=(component
        (ensure-safe-component (bs-default @navComponent (component "bs-navbar/nav")))
        linkToComponent=(component "bs-navbar/link-to" onCollapse=this.collapse class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "nav-link"))
      )
      collapse=this.collapse
      expand=this.expand
      toggleNavbar=this.toggleNavbar
    ) as |yieldedHash|}}
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
      <nav class="navbar {{this.positionClass}} {{this.typeClass}} {{this.breakpointClass}} {{this.backgroundClass}}" ...attributes>
        {{#if this.fluid}}
          {{yield yieldedHash}}
        {{else}}
          <div class="container">
            {{yield yieldedHash}}
          </div>
        {{/if}}
      </nav>
    {{else}}
      <nav class="navbar {{this.positionClass}} {{this.typeClass}}" ...attributes>
        <div class={{if this.fluid "container-fluid" "container"}}>
          {{yield yieldedHash}}
        </div>
      </nav>
    {{/if}}
  {{/let}}
  */
  {
    "id": "twVUO4X6",
    "block": "[[[44,[[28,[37,1],null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[50,[28,[37,3],[[28,[37,4],[[30,1],[50,\"bs-navbar/toggle\",0,null,null]],null]],null],0,null,[[\"onClick\",\"collapsed\"],[[30,0,[\"toggleNavbar\"]],[30,0,[\"_collapsed\"]]]]],[50,[28,[37,3],[[28,[37,4],[[30,2],[50,\"bs-navbar/content\",0,null,null]],null]],null],0,null,[[\"collapsed\",\"onHidden\",\"onShown\"],[[30,0,[\"_collapsed\"]],[30,0,[\"onCollapsed\"]],[30,0,[\"onExpanded\"]]]]],[50,[28,[37,3],[[28,[37,4],[[30,3],[50,\"bs-navbar/nav\",0,null,null]],null]],null],0,null,[[\"linkToComponent\"],[[50,\"bs-navbar/link-to\",0,null,[[\"onCollapse\",\"class\"],[[30,0,[\"collapse\"]],\"nav-link\"]]]]]],[30,0,[\"collapse\"]],[30,0,[\"expand\"]],[30,0,[\"toggleNavbar\"]]]]]],[[[1,\"    \"],[11,\"nav\"],[16,0,[29,[\"navbar \",[30,0,[\"positionClass\"]],\" \",[30,0,[\"typeClass\"]],\" \",[30,0,[\"breakpointClass\"]],\" \",[30,0,[\"backgroundClass\"]]]]],[17,5],[12],[1,\"\\n\"],[41,[30,0,[\"fluid\"]],[[[1,\"        \"],[18,6,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n          \"],[18,6,[[30,4]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[4]]]],[\"@toggleComponent\",\"@contentComponent\",\"@navComponent\",\"yieldedHash\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"bs-default\",\"if\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar.hbs",
    "isStrictMode": false
  });
  /**
    Component to generate [Bootstrap navbars](http://getbootstrap.com/components/#navbar).
  
    ### Usage
  
    Uses the following components by a contextual reference:
  
    ```hbs
    <BsNavbar as |navbar|>
      <div class="navbar-header">
        <navbar.toggle />
        <a class="navbar-brand" href="#">Brand</a>
      </div>
      <navbar.content>
        <navbar.nav as |nav|>
          <nav.item>
            <nav.linkTo @route="home">Home</nav.linkTo>
          </nav.item>
          <nav.item>
            <nav.linkTo @route="navbars">Navbars</nav.linkTo>
          </nav.item>
        </navbar.nav>
      </navbar.content>
    </BsNavbar>
    ```
  
    **Note:** the `<div class="navbar-header">` is required for BS3 to hold the elements visible on a mobile breakpoint,
    when the actual content is collapsed. It should *not* be used for BS4!
  
    The component yields references to the following contextual components:
  
    * [Components.NavbarContent](Components.NavbarContent.html)
    * [Components.NavbarToggle](Components.NavbarToggle.html)
    * [Components.NavbarNav](Components.NavbarNav.html)
  
    Furthermore references to the following actions are yielded:
  
    * `collapse`: triggers the `onCollapse` action and collapses the navbar (mobile)
    * `expand`: triggers the `onExpand` action and expands the navbar (mobile)
    * `toggleNavbar`: triggers the `toggleNavbar` action and toggles the navbar (mobile)
  
    ### Responsive Design
  
    For the mobile breakpoint the Bootstrap styles will hide the navbar content (`{{navbar.content}}`). Clicking on the
    navbar toggle button (`{{navbar.toggle}}`) will expand the menu. By default all nav links (`<nav.linkTo @route="...">`) are already
    wired up to call the navbar's `collapse` action, so clicking any of them will collapse the navbar. To selectively
    prevent that, you can set its `collapseNavbar` property to false:
  
    ```hbs
    <nav.item>
      <nav.linkTo @route="index" @collapseNavbar={{false}}>Don't collapse</nav.linkTo>
    </nav.item>
    ```
  
    To collapse the navbar when clicking on some nav items that are not internal links, you can use the yielded `collapse`
    action:
  
    ```hbs
    <BsNavbar as |navbar|>
      <navbar.content>
        <navbar.nav as |nav|>
          <nav.item>
            <a onclick={{action navbar.collapse}}>Collapse</a>
          </nav.item>
        </navbar.nav>
      </navbar.content>
    </BsNavbar>
    ```
  
    ### Navbar styles
  
    The component supports the default bootstrap navbar styling options through the `type`
    property. Bootstrap navbars [do not currently support justified nav links](http://getbootstrap.com/components/#navbar-default),
    so those are explicitly disallowed.
  
    Other bootstrap navbar variations, such as forms, buttons, etc. can be supported through direct use of
    bootstrap styles applied through the `class` attribute on the components.
  
    ### Bootstrap 3/4 Notes
  
    Bootstrap 4 changed the default navbar styling option from `navbar-default` to `navbar-light`.
    If you explicitly specified "default" in Bootstrap 3 and are migrating, you will need to change
    this in your code. Bootstrap 4 changes `navbar-inverse` to `navbar-dark`.
  
    Bootstrap 4 navbars are fluid by default without the need for an additional container. An
    additional container is added like with Bootstrap 3 if `fluid` is `false`.
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Navbar
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Navbar = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _listenTo.default)('collapsed'), _dec3 = (0, _object2.computed)('position'), _dec4 = (0, _object2.computed)('type'), _dec5 = (0, _object.observes)('_collapsed'), _dec6 = (0, _object2.computed)('toggleBreakpoint'), _dec7 = (0, _object2.computed)('backgroundColor'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class Navbar extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * Manages the state for the responsive menu between the toggle and the content.
       *
       * @property collapsed
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "collapsed", _descriptor, this);
      /**
       * @property _collapsed
       * @private
       */
      _initializerDefineProperty(this, "_collapsed", _descriptor2, this);
      /**
       * Controls whether the wrapping div is a fluid container or not.
       *
       * @property fluid
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "fluid", _descriptor3, this);
      /**
       * Specifies the position classes for the navbar, currently supporting none, "fixed-top", "fixed-bottom", and
       * either "static-top" (BS3) or "sticky-top" (BS4).
       * See the [bootstrap docs](http://getbootstrap.com/components/#navbar-fixed-top) for details.
       *
       * @property position
       * @type String
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "position", _descriptor4, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/navbar/#color-schemes)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      _initializerDefineProperty(this, "type", _descriptor5, this);
      /**
       * Bootstrap 4 Only: Defines the responsive toggle breakpoint size. Options are the standard
       * two character Bootstrap size abbreviations. Used to set the `navbar-expand-*`
       * class. Set to `null` to disable collapsing.
       *
       * @property toggleBreakpoint
       * @type String
       * @default 'lg'
       * @public
       */
      _initializerDefineProperty(this, "toggleBreakpoint", _descriptor6, this);
      /**
       * Bootstrap 4 Only: Sets the background color for the navbar. Can be any color
       * in the set that composes the `bg-*` classes and can be extended by creating your
       * own `bg-*` classes.
       *
       * @property backgroundColor
       * @type String
       * @default 'light'
       * @public
       */
      _initializerDefineProperty(this, "backgroundColor", _descriptor7, this);
    }
    get positionClass() {
      let position = this.position;
      let validPositions = (0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isBS3) ? ['fixed-top', 'fixed-bottom', 'static-top'] : ['fixed-top', 'fixed-bottom', 'sticky-top'];
      let positionPrefix = (0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isBS3) ? 'navbar-' : '';
      if (validPositions.indexOf(position) === -1) {
        return null;
      }
      return `${positionPrefix}${position}`;
    }
    get typeClass() {
      let type = this.type || 'default';
      (true && !(typeof type === 'string' && type !== '') && (0, _debug.assert)('The value of `type` must be a string', typeof type === 'string' && type !== ''));
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isNotBS3)) {
        // 'default` is not a valid type in BS4, but still accepted for compatibility purposes, and mapped to `light'
        if (type === 'default') {
          type = 'light';
        }
      }
      return `navbar-${type}`;
    }

    /**
     * The action to be sent when the navbar is about to be collapsed.
     *
     * You can return false to prevent collapsing the navbar automatically, and do that in your action by
     * setting `collapsed` to true.
     *
     * @event onCollapse
     * @public
     */
    onCollapse() {}

    /**
     * The action to be sent after the navbar has been collapsed (including the CSS transition).
     *
     * @event onCollapsed
     * @public
     */
    onCollapsed() {}

    /**
     * The action to be sent when the navbar is about to be expanded.
     *
     * You can return false to prevent expanding the navbar automatically, and do that in your action by
     * setting `collapsed` to false.
     *
     * @event onExpand
     * @public
     */
    onExpand() {}

    /**
     * The action to be sent after the navbar has been expanded (including the CSS transition).
     *
     * @event onExpanded
     * @public
     */
    onExpanded() {}
    _onCollapsedChange() {
      let collapsed = this._collapsed;
      let active = this.active;
      if (collapsed !== active) {
        return;
      }
      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }

    /**
     * @method expand
     * @private
     */
    expand() {
      if (this.onExpand() !== false) {
        this.set('_collapsed', false);
      }
    }

    /**
     * @method collapse
     * @private
     */
    collapse() {
      if (this.onCollapse() !== false) {
        this.set('_collapsed', true);
      }
    }
    toggleNavbar() {
      if (this._collapsed) {
        this.expand();
      } else {
        this.collapse();
      }
    }
    get breakpointClass() {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isBS3)) {
        return undefined;
      } else {
        let toggleBreakpoint = this.toggleBreakpoint;
        if ((0, _utils.isBlank)(toggleBreakpoint)) {
          return 'navbar-expand';
        } else {
          return `navbar-expand-${toggleBreakpoint}`;
        }
      }
    }
    get backgroundClass() {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isBS3)) {
        return undefined;
      } else {
        return `bg-${this.backgroundColor}`;
      }
    }

    /**
     * @property toggleComponent
     * @type {String}
     * @private
     */

    /**
     * @property contentComponent
     * @type {String}
     * @private
     */

    /**
     * @property navComponent
     * @type {String}
     * @private
     */

    /**
     * @property linkToComponent
     * @type {String}
     * @private
     */
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "_collapsed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fluid", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "position", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "positionClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "positionClass"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "typeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onCollapsedChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCollapsedChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "expand", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "expand"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "collapse", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "collapse"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleNavbar", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleNavbar"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "toggleBreakpoint", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'lg';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "backgroundColor", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'light';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "breakpointClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "breakpointClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "backgroundClass", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "backgroundClass"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Navbar);
});
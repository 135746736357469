define("ember-ref-bucket/modifiers/create-ref", ["exports", "ember-modifier", "@ember/application", "@ember/object", "@ember/debug", "ember-ref-bucket/utils/ref"], function (_exports, _emberModifier, _application, _object, _debug, _ref) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let RefModifier = _exports.default = (_class = class RefModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_key", this.name);
      _defineProperty(this, "_ctx", this.ctx);
      // to minimise overhead, user should be specific about
      // what they want to observe
      _defineProperty(this, "defaultMutationObserverOptions", {
        attributes: false,
        characterData: false,
        childList: false,
        subtree: false
      });
      (0, _ref.setGlobalRef)((0, _application.getOwner)(this));
    }
    markDirty() {
      (0, _ref.bucketFor)(this._ctx).dirtyTrackedCell(this._key);
    }
    cleanMutationObservers() {
      if (this._mutationsObserver) {
        this._mutationsObserver.disconnect();
      }
    }
    cleanResizeObservers() {
      if (this._resizeObserver) {
        this._resizeObserver.unobserve(this.element);
      }
    }
    installMutationObservers() {
      this._mutationsObserver = new MutationObserver(this.markDirty);
      const opts = this.getObserverOptions();
      delete opts.resize;
      if (opts.attributes || opts.characterdata || opts.childlist) {
        // mutations observer throws if observe is attempted
        // with all these options disabled
        this._mutationsObserver.observe(this.element, opts);
      }
    }
    validateTrackedOptions() {
      const args = ['subtree', 'attributes', 'children', 'resize', 'character'];
      if (args.some(name => name in this.args.named)) {
        (true && !(this.isTracked) && (0, _debug.assert)(`"ember-ref-modifier", looks like you trying to use {{${this.args.named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked));
      }
    }
    getObserverOptions() {
      // to minimise overhead user
      // should be specific about
      // what they want to observe
      let resize = false;
      let subtree = this.defaultMutationObserverOptions.subtree;
      let attributes = this.defaultMutationObserverOptions.attributes;
      let character = this.defaultMutationObserverOptions.characterData;
      let children = this.defaultMutationObserverOptions.childList;
      if ('subtree' in this.args.named) {
        subtree = this.args.named.subtree;
      }
      if ('attributes' in this.args.named) {
        attributes = this.args.named.attributes;
      }
      if ('children' in this.args.named) {
        children = this.args.named.children;
      }
      if ('resize' in this.args.named) {
        resize = this.args.named.resize;
      }
      if ('character' in this.args.named) {
        character = this.args.named.character;
      }
      return {
        subtree,
        attributes,
        childList: children,
        resize,
        characterData: character
      };
    }
    installResizeObservers() {
      this._resizeObserver = new ResizeObserver(this.markDirty);
      this._resizeObserver.observe(this.element);
    }
    didReceiveArguments() {
      (true && !(typeof this.name === "string" && this.name.length > 0) && (0, _debug.assert)(`You must provide string as first positional argument for {{${this.args.named.debugName}}}`, typeof this.name === "string" && this.name.length > 0));
      this.validateTrackedOptions();
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      if (this.name !== this._key || this._ctx !== this.ctx) {
        (0, _ref.bucketFor)(this._ctx).add(this._key, null);
      }
      this._ctx = this.ctx;
      this._key = this.name;
      (0, _ref.bucketFor)(this.ctx).add(this.name, this.element);
      if (this.isTracked) {
        this.installMutationObservers();
        if (this.getObserverOptions().resize) {
          this.installResizeObservers();
        }
      }
    }
    get ctx() {
      (true && !(this.args.named.bucket !== null) && (0, _debug.assert)(`ember-ref-bucket: You trying to use {{${this.args.named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${this.args.positional[0]}"}}`, this.args.named.bucket !== null));
      return this.args.named.bucket || (0, _application.getOwner)(this);
    }
    get isTracked() {
      return this.args.named.tracked || false;
    }
    get name() {
      return this.args.positional[0];
    }
    willDestroy() {
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      (0, _ref.getNodeDestructors)(this.element).forEach(cb => cb());
    }
  }, _applyDecoratedDescriptor(_class.prototype, "markDirty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype), _class);
});
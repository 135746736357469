define("ember-bootstrap/components/bs-form/element/layout", ["exports", "@ember-decorators/component", "@ember/component", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _component2, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
  
   @class FormElementLayout
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementLayout = _exports.default = (_dec = (0, _component.tagName)(''), _dec(_class = (_class2 = class FormElementLayout extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property formElementId
       * @type {String}
       * @public
       */
      _initializerDefineProperty(this, "formElementId", _descriptor, this);
      /**
       * @property hasLabel
       * @type boolean
       * @public
       */
      _initializerDefineProperty(this, "hasLabel", _descriptor2, this);
      /**
       * @property errorsComponent
       * @type {Ember.Component}
       * @public
       */
      _initializerDefineProperty(this, "errorsComponent", _descriptor3, this);
      /**
       * @property feedbackIconComponent
       * @type {Ember.Component}
       * @public
       */
      _initializerDefineProperty(this, "feedbackIconComponent", _descriptor4, this);
      /**
       * @property labelComponent
       * @type {Ember.Component}
       * @public
       */
      _initializerDefineProperty(this, "labelComponent", _descriptor5, this);
      /**
       * @property helpTextComponent
       * @type {Ember.Component}
       * @public
       */
      _initializerDefineProperty(this, "helpTextComponent", _descriptor6, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "formElementId", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "errorsComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "feedbackIconComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "labelComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "helpTextComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class2)) || _class);
});
define("ember-cli-adapter-pattern/utils/required-property", ["exports", "@ember/error", "@ember/debug", "@ember/object"], function (_exports, _error, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requiredProperty;
  /*
   * Utility method, returning a computed
   * property that throws an error unless
   * defined. This is like implementing
   * abstract methods.
   *
   * @method requiredProperty
   *
   * @param {String} propertyName
   *   Name of the required property.
   *
   * @return {Function}
   *   An 'abstract' method implementation.
   */
  function requiredProperty(propertyName) {
    (true && !(propertyName) && (0, _debug.assert)('Property name is required for requiredProperty.', propertyName));
    return (0, _object.computed)(function () {
      throw new _error.default(`Definition of property ${propertyName} is required.`);
    });
  }
});
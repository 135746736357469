define("ember-macro-helpers/expand-property", ["exports", "@ember/object/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(property) {
    let newPropertyList = [];
    (0, _computed.expandProperties)(property, expandedProperties => {
      newPropertyList = newPropertyList.concat(expandedProperties);
    });
    return newPropertyList;
  }
});
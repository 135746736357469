define("ember-link-action/initializers/link-action-enhancer", ["exports", "@ember/routing/link-component"], function (_exports, _linkComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LinkActionOverride = void 0;
  _exports.initialize = initialize;
  const LinkActionOverride = _exports.LinkActionOverride = {
    init() {
      this._super(...arguments);
      if (this.invokeAction) {
        this._attachActionEvent();
      }
    },
    willDestroyElement() {
      if (this.invokeAction) {
        this._detachActionEvent();
      }
    },
    _sendInvokeAction() {
      this.invokeAction();
    },
    _attachActionEvent() {
      this.on(this.eventName, this, this._sendInvokeAction);
    },
    _detachActionEvent() {
      this.off(this.eventName, this, this._sendInvokeAction);
    }
  };
  function initialize() {
    _linkComponent.default.reopen(LinkActionOverride);
  }
  var _default = _exports.default = {
    name: 'link-action-enhancer',
    initialize
  };
});
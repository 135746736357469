define("ember-bootstrap/components/bs-form/element/feedback-icon", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <span class="form-control-feedback {{this.iconName}}" aria-hidden="true"></span>
  {{/if}}
  */
  {
    "id": "ZLU4VF7j",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"form-control-feedback \",[30,0,[\"iconName\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/feedback-icon.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementFeedbackIcon
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementFeedbackIcon = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementFeedbackIcon extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * @property show
       * @type {Boolean}
       * @public
       */
      _initializerDefineProperty(this, "show", _descriptor, this);
      /**
       * @property iconName
       * @type {String}
       * @public
       */
      _initializerDefineProperty(this, "iconName", _descriptor2, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "show", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "iconName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementFeedbackIcon);
});
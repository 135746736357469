define("ember-cli-analytics/mixins/trackable", ["exports", "@ember/object/mixin", "@ember/application", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/service"], function (_exports, _mixin, _application, _debug, _object, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    /*
     * Inject the analytics service
     * so we may make use of multiple
     * analytics integrations.
     */
    analytics: (0, _service.inject)(),
    /*
     * Push the page transition to all
     * analytics adapters.
     *
     * @method trackPageView
     * @on didTransition
     */
    trackPageView: (0, _evented.on)('didTransition', function () {
      const analytics = (0, _object.get)(this, 'analytics');
      const owner = (0, _application.getOwner)(this);
      (true && !(analytics) && (0, _debug.assert)('Could not find the analytics service.', analytics));
      let limitRouteInformation = false;
      if (owner) {
        const config = owner.resolveRegistration('config:environment');
        limitRouteInformation = (0, _object.get)(config, 'analytics.options.limitRouteInformation');
      }
      const routeData = limitRouteInformation ? (0, _object.get)(this, 'currentRouteName') : (0, _object.get)(this, 'url');
      analytics.trackPage({
        page: routeData,
        title: routeData
      });
    })
  });
});
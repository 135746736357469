define("ember-bootstrap/components/bs-form/group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-bootstrap/config", "@ember/utils", "ember-bootstrap/utils/decorators/arg"], function (_exports, _component, _templateFactory, _component2, _config, _utils, _arg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{if (macroCondition (macroGetOwnConfig "isNotBS5")) "form-group"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.validationClass}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.hasFeedback "has-feedback")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (bs-size-class "form-group" @size)}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if (bs-eq @formLayout "horizontal") "row")}}"
    ...attributes
  >
    {{yield}}
    {{#if this.hasFeedback}}
      <span class="form-control-feedback {{this.iconName}}" aria-hidden="true"></span>
    {{/if}}
  </div>
  */
  {
    "id": "5LpPN9wi",
    "block": "[[[11,0],[16,0,[29,[\"form-group\",\" \",[27],\" \",[27],\" \",[27],\" \",[52,[28,[37,1],[[30,1],\"horizontal\"],null],\"row\"]]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[41,[30,0,[\"hasFeedback\"]],[[[1,\"    \"],[10,1],[15,0,[29,[\"form-control-feedback \",[30,0,[\"iconName\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@formLayout\",\"&attrs\",\"&default\"],false,[\"if\",\"bs-eq\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/group.hbs",
    "isStrictMode": false
  });
  /**
    This component renders a `<div class="form-group">` element, with support for validation states and feedback icons (only for BS3).
    You can use it as a block level component. The following shows Bootstrap 3 usage for the internal markup.
  
    ```hbs
    <BsForm as |form|>
      <form.group @validation={{this.firstNameValidation}}>
        <label class="control-label">First name</label>
        <input value={{this.firstname}} class="form-control" oninput={{action (mut this.firstname) value="target.value"}} type="text">
      </form.group>
    </bs-form>
    ```
  
    If the `validation` property is set to some state (usually Bootstrap's predefined states "success",
    "warning" or "error"), the appropriate styles will be added, together with a feedback icon.
    See http://getbootstrap.com/css/#forms-control-validation
  
    @class FormGroup
    @namespace Components
    @extends Ember.Component
    @public
  */
  let FormGroup = _exports.default = (_class = class FormGroup extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * [BS3 only] Set to a validation state to render the form-group with a validation style.
       * See http://getbootstrap.com/css/#forms-control-validation
       *
       * The default states of "success", "warning" and "error" are supported by Bootstrap out-of-the-box.
       * But you can use custom states as well. This will set a has-<state> class, and (if `useIcons`is true)
       * a feedback whose class is taken from the <state>Icon property
       *
       * @property validation
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "validation", _descriptor, this);
      /**
       * [BS3 only] Whether to show validation state icons.
       * See http://getbootstrap.com/css/#forms-control-validation
       *
       * @property useIcons
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "useIcons", _descriptor2, this);
      /**
       * [BS3 only] The icon classes to be used for a feedback icon in a "success" validation state.
       * Defaults to the usual glyphicon classes. This is ignored, and no feedback icon is
       * rendered if `useIcons` is false.
       *
       * You can change this globally by setting the `formValidationSuccessIcon` property of
       * the ember-bootstrap configuration in your config/environment.js file. If your are
       * using FontAwesome for example:
       *
       * ```js
       * ENV['ember-bootstrap'] = {
       *   formValidationSuccessIcon: 'fas fa-check'
       * }
       * ```
       *
       * @property successIcon
       * @type string
       * @default 'glyphicon glyphicon-ok'
       * @public
       */
      _initializerDefineProperty(this, "successIcon", _descriptor3, this);
      /**
       * [BS3 only] The icon classes to be used for a feedback icon in a "error" validation state.
       * Defaults to the usual glyphicon classes. This is ignored, and no feedback icon is
       * rendered if `useIcons` is false.
       *
       * You can change this globally by setting the `formValidationErrorIcon` property of
       * the ember-bootstrap configuration in your config/environment.js file. If your are
       * using FontAwesome for example:
       *
       * ```js
       * ENV['ember-bootstrap'] = {
       *   formValidationErrorIcon: 'fas fa-times'
       * }
       * ```
       *
       * @property errorIcon
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "errorIcon", _descriptor4, this);
      /**
       * [BS3 only] The icon classes to be used for a feedback icon in a "warning" validation state.
       * Defaults to the usual glyphicon classes. This is ignored, and no feedback icon is
       * rendered if `useIcons` is false.
       *
       * You can change this globally by setting the `formValidationWarningIcon` property of
       * the ember-bootstrap configuration in your config/environment.js file. If your are
       * using FontAwesome for example:
       *
       * ```js
       * ENV['ember-bootstrap'] = {
       *   formValidationWarningIcon: 'fas fa-exclamation-triangle'
       * }
       * ```
       *
       * @property warningIcon
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "warningIcon", _descriptor5, this);
      /**
       * [BS3 only] The icon classes to be used for a feedback icon in a "info" validation state.
       * Defaults to the usual glyphicon classes. This is ignored, and no feedback icon is
       * rendered if `useIcons` is false.
       *
       * You can change this globally by setting the `formValidationInfoIcon` property of
       * the ember-bootstrap configuration in your config/environment.js file. If your are
       * using FontAwesome for example:
       *
       * ```js
       * ENV['ember-bootstrap'] = {
       *   formValidationInfoIcon: 'fa fa-info-circle
       * }
       * ```
       *
       * The "info" validation state is not supported in Bootstrap CSS, but can be easily added
       * using the following LESS style:
       * ```less
       * .has-info {
       *   .form-control-validation(@state-info-text; @state-info-text; @state-info-bg);
       * }
       * ```
       *
       * @property infoIcon
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "infoIcon", _descriptor6, this);
    }
    /**
     * @property classTypePrefix
     * @type String
     * @default 'form-group' (BS3) or 'form-control' (BS4)
     * @private
     */

    /**
     * Computed property which is true if the form group is in a validation state
     *
     * @property hasValidation
     * @type boolean
     * @private
     * @readonly
     */
    get hasValidation() {
      return (0, _utils.isPresent)(this.validation);
    }
    /**
     * [BS3 only] Computed property which is true if the form group is showing a validation icon
     *
     * @property hasFeedback
     * @type boolean
     * @private
     * @readonly
     */
    get hasFeedback() {
      return this.hasValidation && this.useIcons && this.hasIconForValidationState;
    }
    /**
     * [BS3 only]
     *
     * @property iconName
     * @type string
     * @readonly
     * @private
     */
    get iconName() {
      let validation = this.validation || 'success';
      return this[`${validation}Icon`];
    }

    /**
     * [BS3 only]
     *
     * @property hasIconForValidationState
     * @type boolean
     * @readonly
     * @private
     */
    get hasIconForValidationState() {
      return (0, _utils.isPresent)(this.iconName);
    }

    /**
     * [BS3 only]
     *
     * @property validationClass
     * @type string
     * @readonly
     * @private
     */
    get validationClass() {
      let validation = this.validation;
      return !(0, _utils.isBlank)(validation) ? `has-${validation}` : undefined;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "validation", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "useIcons", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "successIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationSuccessIcon;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationErrorIcon;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "warningIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationWarningIcon;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "infoIcon", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _config.default.formValidationInfoIcon;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormGroup);
});
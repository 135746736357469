define("ember-bootstrap/components/bs-tooltip", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-contextual-help", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _bsContextualHelp, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-unbound }}
  {{unbound this._parentFinder}}
  {{#if this.inDom}}
    {{#let (ensure-safe-component (bs-default @elementComponent (component "bs-tooltip/element"))) as |Element|}}
      <Element
        @placement={{this.placement}}
        @fade={{this.fade}}
        @showHelp={{this.showHelp}}
        @renderInPlace={{this._renderInPlace}}
        @destinationElement={{this.destinationElement}}
        @popperTarget={{this.triggerTargetElement}}
        @autoPlacement={{this.autoPlacement}}
        @viewportElement={{this.viewportElement}}
        @viewportPadding={{this.viewportPadding}}
        @class={{@class}}
  
        {{create-ref "overlayElement"}}
        ...attributes
      >
        {{#if (has-block)}}
          {{yield
            (hash
              close=this.close
            )
          }}
        {{else}}
          {{@title}}
        {{/if}}
      </Element>
    {{/let}}
  {{/if}}
  {{did-insert this.setup}}
  */
  {
    "id": "wHyYHS7f",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_parentFinder\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"inDom\"]],[[[44,[[28,[37,3],[[28,[37,4],[[30,1],[50,\"bs-tooltip/element\",0,null,null]],null]],null]],[[[1,\"    \"],[8,[30,2],[[17,3],[4,[38,6],[\"overlayElement\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@renderInPlace\",\"@destinationElement\",\"@popperTarget\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@class\"],[[30,0,[\"placement\"]],[30,0,[\"fade\"]],[30,0,[\"showHelp\"]],[30,0,[\"_renderInPlace\"]],[30,0,[\"destinationElement\"]],[30,0,[\"triggerTargetElement\"]],[30,0,[\"autoPlacement\"]],[30,0,[\"viewportElement\"]],[30,0,[\"viewportPadding\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"        \"],[18,6,[[28,[37,9],null,[[\"close\"],[[30,0,[\"close\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]],[]],null],[1,[28,[35,10],[[30,0,[\"setup\"]]],null]]],[\"@elementComponent\",\"Element\",\"&attrs\",\"@class\",\"@title\",\"&default\"],false,[\"unbound\",\"if\",\"let\",\"ensure-safe-component\",\"bs-default\",\"component\",\"create-ref\",\"has-block\",\"yield\",\"hash\",\"did-insert\"]]",
    "moduleName": "ember-bootstrap/components/bs-tooltip.hbs",
    "isStrictMode": false
  });
  /**
    Component that implements Bootstrap [tooltips](http://getbootstrap.com/javascript/#tooltips).
  
    By default it will attach its listeners (mouseover and focus) to the parent DOM element to trigger
    the tooltip:
  
    ```hbs
    <button class="btn">
      <BsTooltip @title="This is a toolip" />
    </button>
    ```
  
    You can also use the component in a block form to set the title:
  
    ```hbs
    <button class="btn">
      <BsTooltip>
        This is a toolip
      </BsTooltip>
    </button>
    ```
  
    ### Trigger
  
    The trigger element is the DOM element that will cause the tooltip to be shown when one of the trigger events occur on
    that element. By default the trigger element is the parent DOM element of the component, and the trigger events will be
    "hover" and "focus".
  
    The `triggerElement` property accepts any CSS selector to attach the tooltip to any other existing DOM element.
    With the special value "parentView" you can attach the tooltip to the DOM element of the parent component:
  
    ```hbs
    <MyComponent>
      <BsTooltip @title="This is a toolip" @triggerElement="parentView"/>
    </MyComponent>
    ```
  
    To customize the events that will trigger the tooltip use the `triggerEvents` property, that accepts an array or a
    string of events, with "hover", "focus" and "click" being supported.
  
    ### Placement options
  
    By default the tooltip will show up on top of the trigger element. Use the `placement` property to change that
    ("top", "bottom", "left" and "right"). To make sure the tooltip will not exceed the viewport (see Advanced customization)
    you can set `autoPlacement` to true. A tooltip with `placement="right" will be placed to the right if there is enough
    space, otherwise to the left.
  
    ### Advanced customization
  
    Several other properties allow for some advanced customization:
    * `visible` to show/hide the tooltip programmatically
    * `fade` to disable the fade in transition
    * `delay` (or `delayShow` and `delayHide`) to add a delay
    * `viewportSelector` and `viewportPadding` to customize the viewport that affects `autoPlacement`
    * a `close` action is yielded, that allows you to close the tooltip:
  
    ```hbs
    <BsTooltip as |tt|>This is a toolip <button onclick={{action tt.close}}>Close</button></BsTooltip>
    ```
  
    See the individual API docs for each property.
  
    ### Actions
  
    When you want to react on the tooltip being shown or hidden, you can use one of the following supported actions:
    * `onShow`
    * `onShown`
    * `onHide`
    * `onHidden`
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Tooltip
    @namespace Components
    @extends Components.ContextualHelp
    @public
  */
  let Tooltip = _exports.default = (0, _deprecateSubclassing.default)(_class = class Tooltip extends _bsContextualHelp.default {
    /**
     * @property elementComponent
     * @type {String}
     * @private
     */

    /**
     * The DOM element of the arrow element.
     *
     * @property arrowElement
     * @type object
     * @readonly
     * @private
     */
    get arrowElement() {
      return this.overlayElement.querySelector('.tooltip-arrow');
    }
  }) || _class;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Tooltip);
});
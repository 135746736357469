define("ember-bootstrap/components/bs-form/element/control/switch", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _control, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    type="checkbox"
    id={{@id}}
    disabled={{this.disabled}}
    readonly={{this.readonly}}
    aria-describedby={{this.ariaDescribedBy}}
    checked={{this.value}}
    class="{{if (macroCondition (macroGetOwnConfig "isBS5")) "form-check-input" "custom-control-input"}} {{this.formValidationClass}}"
    ...attributes
    {{on "click" this.handleClick}}
  >
  
  */
  {
    "id": "SFSdjG3b",
    "block": "[[[11,\"input\"],[24,4,\"checkbox\"],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,\"checked\",[30,0,[\"value\"]]],[16,0,[29,[\"custom-control-input\",\" \",[30,0,[\"formValidationClass\"]]]]],[17,2],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[13],[1,\"\\n\"]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/control/switch.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementControlSwitch
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlSwitch = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementControlSwitch extends _control.default {
    handleClick(event) {
      this.onChange(event.target.checked);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementControlSwitch);
});
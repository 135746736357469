define("ember-bootstrap/helpers/bs-type-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeClassHelper = typeClassHelper;
  function typeClassHelper([prefix, type], {
    default: defaultValue,
    outline = false
  }) {
    type = type ?? defaultValue;
    if (outline) {
      return `${prefix}-outline-${type}`;
    }
    return `${prefix}-${type}`;
  }
  var _default = _exports.default = (0, _helper.helper)(typeClassHelper);
});
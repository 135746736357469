define("ember-bootstrap/components/bs-navbar/toggle", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "navbar-toggler" "navbar-toggle"}} {{if this.collapsed "collapsed"}}" ...attributes {{on "click" this.handleClick}}>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
        <span class="navbar-toggler-icon"></span>
      {{else}}
        <span class={{if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only"}}>Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      {{/if}}
    {{/if}}
  </button>
  */
  {
    "id": "0byxcz9B",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"navbar-toggler\",\" \",[52,[30,0,[\"collapsed\"]],\"collapsed\"]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"navbar-toggler-icon\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar/toggle.hbs",
    "isStrictMode": false
  });
  /**
   * Component to implement the responsive menu toggle behavior in a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * ### Bootstrap 3/4 Notes
   *
   * The inline version of the component uses the triple `icon-bar` styling for Bootstrap 3 and the
   * `navbar-toggler-icon` styling for Bootstrap 4.
   *
   * @class NavbarToggle
   * @namespace Components
   * @extends Ember.Component
   * @public
   */
  let NavbarToggle = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class NavbarToggle extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "collapsed", _descriptor, this);
    }
    /**
     * @event onClick
     * @public
     */
    onClick() {}
    handleClick() {
      this.onClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavbarToggle);
});
define("ember-cli-analytics/integrations/google-adwords", ["exports", "jquery", "ember-cli-analytics/integrations/base", "ember-cli-analytics/utils/can-use-dom", "ember-cli-analytics/utils/without", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/polyfills", "@ember/utils"], function (_exports, _jquery, _base, _canUseDom, _without, _debug, _object, _evented, _polyfills, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackConversion(options = {}) {
      const config = (0, _object.get)(this, 'config');
      const {
        id,
        label
      } = (0, _polyfills.assign)({}, config);
      const {
        value
      } = options;
      const properties = (0, _without.default)(options, 'value');
      (true && !(id) && (0, _debug.assert)('You must pass a valid `id` to the GoogleAdwords adapter', id));
      (true && !(label) && (0, _debug.assert)('You must pass a valid `label` to the GoogleAdwords adapter', label));
      const googleAdwordsEvent = (0, _polyfills.assign)({}, {
        'google_conversion_id': id,
        'google_conversion_language': 'en',
        'google_conversion_format': '3',
        'google_conversion_color': 'ffffff',
        'google_conversion_label': label,
        'google_remarketing_only': false
      }, properties);
      if ((0, _utils.isPresent)(value)) {
        googleAdwordsEvent['google_conversion_value'] = value;
      }
      if (_canUseDom.default) {
        window['google_trackConversion'](googleAdwordsEvent);
      }
    },
    /*
     * Insert the JavaScript tag into the
     * page, and perform any necessary
     * setup.
     *
     * @method insertTag
     * @on init
     */
    insertTag: (0, _evented.on)('init', function () {
      if (_canUseDom.default && !window['google_trackConversion']) {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i[r] = i[r] || function () {
            (i['r'].q = i['r'].q || []).push(arguments);
          };
          a = s.createElement(o), m = s.getElementsByTagName(o)[0];
          a.async = 0;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//www.googleadservices.com/pagead/conversion_async.js', 'google_trackConversion');
        /* eslint-enable */
      }
    }),
    /*
     * Remove the JavaScript tag from the
     * page, and perform any necessary
     * teardown.
     *
     * @method removeTag
     * @on willDestroy
     */
    removeTag: (0, _evented.on)('willDestroy', function () {
      if (_canUseDom.default) {
        (0, _jquery.default)('script[src*="googleadservices"]').remove();
        delete window.google_trackConversion;
      }
    })
  });
});
define("ember-bootstrap/components/bs-modal/header/title", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
    <h5 class="modal-title" ...attributes>
      {{yield}}
    </h5>
  {{/if}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <h4 class="modal-title" ...attributes>
      {{yield}}
    </h4>
  {{/if}}
  */
  {
    "id": "LYoRW3av",
    "block": "[[[1,\"  \"],[11,\"h5\"],[24,0,\"modal-title\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/header/title.hbs",
    "isStrictMode": false
  });
  /**
  
   @class ModalHeaderTitle
   @namespace Components
   @extends Glimmer.Component
   @private
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
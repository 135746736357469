define("ember-bootstrap/components/bs-accordion/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-bootstrap/utils/cp/type-class", "@ember/object/internals", "ember-bootstrap/utils/deprecate-subclassing", "ember-bootstrap/utils/decorators/arg", "@embroider/macros/runtime"], function (_exports, _component, _templateFactory, _component2, _typeClass, _internals, _deprecateSubclassing, _arg, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (component (ensure-safe-component (bs-default @titleComponent (component "bs-accordion/item/title"))) collapsed=this.collapsed disabled=@disabled onClick=(fn (bs-default @onClick (bs-noop)) this.value))
    (component (ensure-safe-component (bs-default @bodyComponent (component "bs-accordion/item/body"))) collapsed=this.collapsed)
  as |Title Body|
  }}
    <div
      class="{{if @disabled "disabled"}} {{this.typeClass}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel"}}"
      ...attributes
    >
      {{#if (has-block-params)}}
        {{yield
          (hash
            title=Title
            body=Body        )
        }}
      {{else}}
        <Title>
          {{@title}}
        </Title>
        <Body>
        {{yield}}
        </Body>
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "DcW683f7",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,1],[50,\"bs-accordion/item/title\",0,null,null]],null]],null],0,null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,2],[28,[37,4],[[28,[37,3],[[30,3],[28,[37,5],null,null]],null],[30,0,[\"value\"]]],null]]]],[50,[28,[37,2],[[28,[37,3],[[30,4],[50,\"bs-accordion/item/body\",0,null,null]],null]],null],0,null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]]]],[[[1,\"  \"],[11,0],[16,0,[29,[[52,[30,2],\"disabled\"],\" \",[30,0,[\"typeClass\"]],\" \",\"card\",\" \",[27]]]],[17,7],[12],[1,\"\\n\"],[41,[49,[30,9]],[[[1,\"      \"],[18,9,[[28,[37,9],null,[[\"title\",\"body\"],[[30,5],[30,6]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,5],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,8]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,6],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,9,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[5,6]]]],[\"@titleComponent\",\"@disabled\",\"@onClick\",\"@bodyComponent\",\"Title\",\"Body\",\"&attrs\",\"@title\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"bs-default\",\"fn\",\"bs-noop\",\"if\",\"has-block-params\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item.hbs",
    "isStrictMode": false
  });
  /**
   A collapsible/expandable item within an accordion
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  let AccordionItem = _exports.default = (_dec = (0, _typeClass.default)((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isNotBS3) ? 'bg' : 'panel', 'type'), (0, _deprecateSubclassing.default)(_class = (_class2 = class AccordionItem extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * The title of the accordion item, displayed as a .panel-title element
       *
       * @property title
       * @type string
       * @public
       */
      /**
       * The value of the accordion item, which is used as the value of the `selected` property of the parent [Components.Accordion](Components.Accordion.html) component
       *
       * @property value
       * @public
       */
      _initializerDefineProperty(this, "value", _descriptor, this);
      /**
       * @property disabled
       * @type boolean
       * @public
       */
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/navbar/#color-schemes)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      _initializerDefineProperty(this, "type", _descriptor2, this);
      _initializerDefineProperty(this, "typeClass", _descriptor3, this);
    }
    /**
     * @property selected
     * @private
     */

    /**
     * @property titleComponent
     * @type {String}
     * @private
     */

    /**
     * @property bodyComponent
     * @type {String}
     * @private
     */

    /**
     * @property collapsed
     * @type boolean
     * @readonly
     * @private
     */
    get collapsed() {
      return this.value !== this.args.selected;
    }
    /**
     * Reference to the parent `Components.Accordion` class.
     *
     * @property accordion
     * @private
     */
    /**
     * @event onClick
     * @public
     */
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "value", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "type", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionItem);
});
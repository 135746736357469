define("ember-cli-analytics/services/analytics", ["exports", "@ember/service", "ember-cli-adapter-pattern/mixins/adaptable", "ember-cli-adapter-pattern/utils/proxy-to-adapter", "@ember/application", "@ember/array", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/string"], function (_exports, _service, _adaptable, _proxyToAdapter, _application, _array, _debug, _object, _evented, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend(_adaptable.default, {
    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     */
    trackPage: (0, _proxyToAdapter.default)('trackPage'),
    /*
     * Send an arbitrary event to the
     * anlytics engine.
     *
     * @method trackEvent
     */
    trackEvent: (0, _proxyToAdapter.default)('trackEvent'),
    /*
     * Send a conversion completion
     * event to the analytics engine.
     *
     * @method trackConversion
     */
    trackConversion: (0, _proxyToAdapter.default)('trackConversion'),
    /*
     * Identify an anonymous user with a
     * unique ID. This is useful when a
     * user returns to the application
     * an we wish to further track them.
     *
     * This should not be called in
     * conjunction with alias.
     *
     * @method identity
     */
    identify: (0, _proxyToAdapter.default)('identify'),
    /*
     * For those platforms that support
     * it, map an anonymous user id to a
     * registered user. This is useful
     * when you wish to associate events
     * made before the user registerd
     * with a newly created user account.
     *
     * @method identify
     */
    alias: (0, _proxyToAdapter.default)('alias'),
    /*
     * Fetch the adapter configuation and
     * ensure that we have a clean cache
     * of adapters.
     *
     * Further to this we register our
     * adapter classes with the option
     * to not instatiate them immediately.
     *
     * @method createAdapters
     * @on init
     */
    createAdapters: (0, _evented.on)('init', function () {
      const adapters = (0, _object.getWithDefault)(this, 'config.analytics.integrations', (0, _array.A)());
      const owner = (0, _application.getOwner)(this);

      // Integrations should not be instantiated.
      owner.registerOptionsForType('ember-cli-analytics@integration', {
        instantiate: false
      });
      owner.registerOptionsForType('integration', {
        instantiate: false
      });
      (0, _object.set)(this, '_adapters', {});
      (0, _object.set)(this, 'context', {});
      this.activateAdapters(adapters);
    }),
    /*
     * Lookup adapters from the application
     * container.
     *
     * @method lookupAdapter
     *
     * @param {String} adapterName
     *   Name of the adapter.
     *
     * @return {Object}
     *   Uninstantiated adapter object.
     */
    _lookupAdapter(adapterName) {
      (true && !(adapterName) && (0, _debug.assert)('Could not find integration without a name', adapterName));
      const owner = (0, _application.getOwner)(this);
      const dasherizedAdapterName = (0, _string.dasherize)(adapterName);
      const localAdapter = owner.lookup(`ember-cli-analytics@integration:${dasherizedAdapterName}`);
      const adapter = owner.lookup(`integration:${dasherizedAdapterName}`);
      return adapter ? adapter : localAdapter;
    }
  });
});
define("ember-bootstrap/components/bs-carousel/slide", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "ember-bootstrap/mixins/component-child", "@ember/runloop", "ember-bootstrap/utils/cp/overrideable", "@ember/object/observers", "ember-bootstrap/utils/deprecate-subclassing", "ember-ref-bucket"], function (_exports, _component, _templateFactory, _component2, _object, _componentChild, _runloop, _overrideable, _observers, _deprecateSubclassing, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "carousel-item"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "item"}} {{if this.active "active"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.left "carousel-item-left")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.left "left")}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.next "carousel-item-next")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.next "next")}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.prev "carousel-item-prev")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.prev "prev")}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.right "carousel-item-right")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.right "right")}}"
    ...attributes
    {{create-ref "mainNode"}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "P1rOZXBu",
    "block": "[[[11,0],[16,0,[29,[\"carousel-item\",\" \",[27],\" \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"left\"]],\"carousel-item-left\"],\" \",[27],\" \",[52,[30,0,[\"next\"]],\"carousel-item-next\"],\" \",[27],\" \",[52,[30,0,[\"prev\"]],\"carousel-item-prev\"],\" \",[27],\" \",[52,[30,0,[\"right\"]],\"carousel-item-right\"],\" \",[27]]]],[17,1],[4,[38,1],[\"mainNode\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"create-ref\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-carousel/slide.hbs",
    "isStrictMode": false
  });
  /**
    A visible user-defined slide.
  
    See [Components.Carousel](Components.Carousel.html) for examples.
  
    @class CarouselSlide
    @namespace Components
    @extends Ember.Component
    @public
   */
  let CarouselSlide = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _emberRefBucket.ref)('mainNode'), _dec3 = (0, _overrideable.default)('isCurrentSlide', 'presentationState', function () {
    return this.isCurrentSlide && this.presentationState === null;
  }), _dec4 = (0, _object.computed)('currentSlide').readOnly(), _dec5 = (0, _object.computed)('followingSlide').readOnly(), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class CarouselSlide extends _component.default.extend(_componentChild.default) {
    constructor(...args) {
      super(...args);
      /**
       * @property _element
       * @type null | HTMLElement
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor, this);
      /**
       * Defines slide visibility.
       *
       * @property active
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "active", _descriptor2, this);
      /**
       * Slide is moving to the left.
       *
       * @property left
       * @type boolean
       * @private
       */
      _defineProperty(this, "left", false);
      /**
       * Next to appear in a left sliding.
       *
       * @property next
       * @type boolean
       * @private
       */
      _defineProperty(this, "next", false);
      /**
       * Next to appear in a right sliding.
       *
       * @property prev
       * @type boolean
       * @private
       */
      _defineProperty(this, "prev", false);
      /**
       * Slide is moving to the right.
       *
       * @property right
       * @type boolean
       * @private
       */
      _defineProperty(this, "right", false);
    }
    /**
     * @private
     * @property isCurrentSlide
     * @type boolean
     */
    get isCurrentSlide() {
      return this.currentSlide === this;
    }

    /**
     * @private
     * @property isFollowingSlide
     * @type boolean
     */
    get isFollowingSlide() {
      return this.followingSlide === this;
    }
    /**
     * Coordinates the execution of a presentation.
     *
     * @method presentationStateObserver
     * @private
     */
    presentationStateObserver() {
      let presentationState = this.presentationState;
      if (this.isCurrentSlide) {
        switch (presentationState) {
          case 'didTransition':
            this.currentSlideDidTransition();
            break;
          case 'willTransit':
            this.currentSlideWillTransit();
            break;
        }
      }
      if (this.isFollowingSlide) {
        switch (presentationState) {
          case 'didTransition':
            this.followingSlideDidTransition();
            break;
          case 'willTransit':
            this.followingSlideWillTransit();
            break;
        }
      }
    }
    init() {
      super.init(...arguments);
      (0, _observers.addObserver)(this, 'presentationState', null, this.presentationStateObserver, true);
    }

    /**
     * @method currentSlideDidTransition
     * @private
     */
    currentSlideDidTransition() {
      this.set(this.directionalClassName, false);
      this.set('active', false);
    }

    /**
     * @method currentSlideWillTransit
     * @private
     */
    currentSlideWillTransit() {
      this.set('active', true);
      (0, _runloop.next)(this, function () {
        this.set(this.directionalClassName, true);
      });
    }

    /**
     * @method followingSlideDidTransition
     * @private
     */
    followingSlideDidTransition() {
      this.set('active', true);
      this.set(this.directionalClassName, false);
      this.set(this.orderClassName, false);
    }

    /**
     * @method followingSlideWillTransit
     * @private
     */
    followingSlideWillTransit() {
      this.set(this.orderClassName, true);
      (0, _runloop.next)(this, function () {
        this.reflow();
        this.set(this.directionalClassName, true);
      });
    }

    /**
     * Makes things more stable, especially when fast changing.
     */
    reflow() {
      this._element.offsetHeight;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isCurrentSlide", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isCurrentSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFollowingSlide", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isFollowingSlide"), _class2.prototype), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselSlide);
});
define("ember-bootstrap/version", ["exports", "ember", "@embroider/macros/runtime"], function (_exports, _ember, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERSION = void 0;
  _exports.registerLibrary = registerLibrary;
  const VERSION = _exports.VERSION = (0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").version;
  function registerLibrary() {
    _ember.default.libraries.register('Ember Bootstrap', VERSION);
  }
});
define("ember-bootstrap/helpers/bs-default", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bsDefault = bsDefault;
  _exports.default = void 0;
  function bsDefault(params) {
    return params[0] ?? params[1];
  }
  var _default = _exports.default = (0, _helper.helper)(bsDefault);
});
define("ember-cli-adapter-pattern/utils/required-method", ["exports", "@ember/error", "@ember/debug"], function (_exports, _error, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requiredMethod;
  /*
   * Utility method, returning a function that
   * throws an error unless defined. This is
   * like implementing abstract methods.
   *
   * @method requiredMethod
   *
   * @param {String} methodName
   *   Name of the required method.
   *
   * @return {Function}
   *   An 'abstract' method implementation.
   */
  function requiredMethod(methodName) {
    (true && !(methodName) && (0, _debug.assert)('Method name is required for requiredMethod.', methodName));
    return function () {
      throw new _error.default(`Definition of method ${methodName} is required.`);
    };
  }
});
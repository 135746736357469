define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/debug", "@ember/utils", "ember-bootstrap/components/bs-form/element/layout", "ember-bootstrap/utils/default-decorator", "@embroider/macros/runtime"], function (_exports, _component, _templateFactory, _object, _debug, _utils, _layout, _defaultDecorator, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasLabel}}
    <@labelComponent @labelClass={{@horizontalLabelGridClass}}/>
    <div class={{this.horizontalInputGridClass}}>
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        <@feedbackIconComponent/>
      {{/if}}
      <@errorsComponent/>
      <@helpTextComponent/>
    </div>
  {{else}}
    <div class="{{this.horizontalInputGridClass}} {{this.horizontalInputOffsetGridClass}}">
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        <@feedbackIconComponent/>
      {{/if}}
      <@errorsComponent/>
      <@helpTextComponent/>
    </div>
  {{/if}}
  */
  {
    "id": "3halle79",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[8,[30,1],null,[[\"@labelClass\"],[[30,2]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"horizontalInputGridClass\"]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[8,[30,3],null,null,null],[1,\"\\n    \"],[8,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[8,[30,3],null,null,null],[1,\"\\n    \"],[8,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@labelComponent\",\"@horizontalLabelGridClass\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/horizontal.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementLayoutHorizontal
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutHorizontal = _exports.default = (_dec = (0, _object.computed)('horizontalLabelGridClass').readOnly(), _dec2 = (0, _object.computed)('horizontalLabelGridClass'), _class = class FormElementLayoutHorizontal extends _layout.default {
    constructor(...args) {
      super(...args);
      /**
       * The Bootstrap grid class for form labels within a horizontal layout form.
       *
       * @property horizontalLabelGridClass
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "horizontalLabelGridClass", _descriptor, this);
    }
    /**
     * Computed property that specifies the Bootstrap grid class for form controls within a horizontal layout form.
     *
     * @property horizontalInputGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputGridClass() {
      if ((0, _utils.isBlank)(this.horizontalLabelGridClass)) {
        return undefined;
      }
      let parts = this.horizontalLabelGridClass.split('-');
      (true && !(parts.length === 3) && (0, _debug.assert)('horizontalInputGridClass must match format bootstrap grid column class', parts.length === 3));
      parts[2] = 12 - parts[2];
      return parts.join('-');
    }

    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputOffsetGridClass() {
      if ((0, _utils.isBlank)(this.horizontalLabelGridClass)) {
        return undefined;
      }
      let parts = this.horizontalLabelGridClass.split('-');
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/builds/clap-tech/clap-video/ClapVideoFront/node_modules/ember-bootstrap").isBS3)) {
        parts.splice(2, 0, 'offset');
      } else {
        parts.splice(0, 1, 'offset');
      }
      return parts.join('-');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "horizontalInputGridClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputGridClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "horizontalInputOffsetGridClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputOffsetGridClass"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLayoutHorizontal);
});
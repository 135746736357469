define("ember-cli-adapter-pattern/utils/proxy-to-adapter", ["exports", "@ember/error", "@ember/debug"], function (_exports, _error, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = proxyToAdapter;
  /*
   * Utility method, returning a proxy function
   * that looks up a function on the intended
   * adapter. The proxy funtion will return a
   * resolved promise with a value.
   *
   * @method proxyToAdapter
   *
   * @param {String} methodName
   *   Name of the method to proxy.
   *
   * @return {Function}
   *   A proxy function returning a resolved promise.
   */
  function proxyToAdapter(methodName) {
    (true && !(methodName) && (0, _debug.assert)('Method name is required for proxyToAdapter.', methodName));
    return function (...args) {
      if (!this.invoke && typeof this.invoke !== 'function') {
        throw new _error.default('No invoke method. Have you forgotten to include the Adaptable mixin?');
      }
      return this.invoke(methodName, ...args);
    };
  }
});